"use client";
import { NotAuthenticatedErrorBoundary } from "@/components/section/error-boundary/not-authenticated-error-boundary";
import { getAllowed, getParsingGrant, isParsePremium } from "@/customClaims";
import { initializeCrisp } from "@/lib/crisp";
import {
  DEFAULT_LOGIN_PROVIDER,
  LoginProvider,
  providerMap,
} from "@/lib/firebase/auth";
import { runtimeFlags } from "@/lib/runtime";
import { client } from "@llamaindex/cloud";
import { User as OidcUser } from "@llamaindex/cloud/api";
import { Loading } from "@llamaindex/component/ui/loading";
import { usePathname } from "next/navigation";
import {
  createContext,
  ReactNode,
  use,
  useContext,
  useEffect,
  useState,
} from "react";
import { ErrorBoundary } from "react-error-boundary";

const AuthContext = createContext<{
  user: User | null;
  loading: boolean;
}>({
  user: null,
  loading: true,
});

export interface Features {
  grant_parse_premium: ReturnType<typeof isParsePremium>;
}

export interface User {
  email: string | undefined;
  uid: string | undefined;
  displayName: string | undefined;
  photoURL: string | undefined;
  allowed: ReturnType<typeof getAllowed>;
  parsePremium: ReturnType<typeof isParsePremium>;
  parsingGrant: ReturnType<typeof getParsingGrant>;
  isStaff: boolean | false;
  features: Features;
  lastLoginProvider: LoginProvider | "oidc";
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_AUTH_DISABLED === "true") {
      // create a mock user
      setUser({
        email: "mock@runllama.ai",
        // this is the default user_id used by the backend for a mock user
        uid: "0000000000000000000000000000002b",
        displayName: "Mock",
        photoURL: undefined,
        allowed: getAllowed({}),
        parsingGrant: getParsingGrant({}),
        parsePremium: isParsePremium({}),
        isStaff: false,
        features: {
          grant_parse_premium: false,
        },
        lastLoginProvider: LoginProvider.GOOGLE,
      });
      setLoading(false);
      return;
    }

    const isStaffEmail = (email: string): boolean => {
      const pattern = /^[^\s@]+@runllama\.ai$/i;
      return pattern.test(email);
    };

    const determineLastLoginProvider = (decodedClaims: any): LoginProvider => {
      // 1. Check if the last login provider is stored in local storage
      const lastLoginProvider = localStorage.getItem("lastLoginProvider");
      if (lastLoginProvider && lastLoginProvider in LoginProvider) {
        return lastLoginProvider as LoginProvider;
      }
      // 2. Check if the last login provider is stored in the decoded claims
      const providerId = decodedClaims.providerData[0]?.providerId;
      if (providerId) {
        return providerMap[providerId] ?? DEFAULT_LOGIN_PROVIDER;
      }
      // 3. If all else fails, return the default login provider
      return DEFAULT_LOGIN_PROVIDER;
    };

    const firebaseAuthCheck = async () => {
      try {
        const loginRes = await fetch("/api/login");
        if (!loginRes.ok) {
          throw new Error("Not authenticated");
        }

        const decodedClaims = await loginRes.json();

        const currentUser = {
          email: decodedClaims.email,
          uid: decodedClaims.uid,
          displayName: decodedClaims.displayName,
          photoURL: decodedClaims.photoURL,
          allowed: getAllowed(decodedClaims),
          parsePremium: isParsePremium(decodedClaims),
          parsingGrant: getParsingGrant(decodedClaims),
          isStaff: isStaffEmail(decodedClaims.email),
          features: {
            grant_parse_premium: isParsePremium(decodedClaims),
          },
          lastLoginProvider: determineLastLoginProvider(decodedClaims),
        } as User;

        // Try catch to prevent errors from breaking the app if Crisp is not initialized
        try {
          if (currentUser?.parsePremium || currentUser?.allowed.index) {
            initializeCrisp(currentUser);
          }
        } catch (error) {
          console.error("Error Initializing Crisp", error);
        }

        await import("@datadog/browser-rum")
          .then(({ datadogRum }) => {
            datadogRum.setUser({
              id: currentUser.uid,
              email: currentUser.email,
              name: currentUser.displayName,
            });
          })
          .catch(console.error);

        return currentUser;
      } catch (error) {
        console.error("error", error);
        return null;
      }
    };

    const oidcAuthCheck = async () => {
      const currentUser = await fetch(
        `${client.getConfig().baseUrl}/api/v1/auth/me`,
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error("Not authenticated");
          }
          return res.json();
        })
        .then((data: OidcUser) => {
          const uid =
            data.id.indexOf(":") >= 0 ? data.id.split(":")[1] : data.id;
          return {
            email: data.email,
            uid,
            displayName: data.name,
            allowed: {
              index: data.claims?.allowed_index,
              eval: data.claims?.allowed_eval,
              extraction: data.claims?.allowed_extraction,
            },
            parsePremium: data.claims?.parse_premium,
            parsingGrant: data.claims?.usage_pdf_max_pages_per_day,
            isStaff: isStaffEmail(data.email),
            features: {
              grant_parse_premium: data.claims?.parse_premium,
            },
            lastLoginProvider: "oidc",
          } as User;
        })
        .catch((error) => {
          console.error("error", error);
          return null;
        });

      return currentUser;
    };

    const authCheck =
      runtimeFlags.authMode === "self-host-byoc"
        ? oidcAuthCheck
        : firebaseAuthCheck;

    const checkAuthState = async () => {
      const currentUser = await authCheck();
      setUser(currentUser);
      setLoading(false);
    };

    checkAuthState().catch(console.error);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={NotAuthenticatedErrorBoundary}>
      <AuthContext.Provider value={{ user, loading }}>
        {children}
      </AuthContext.Provider>
    </ErrorBoundary>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export const AuthReady = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname();
  // do not show loading screen for login page
  const loginPath = "/login";
  if (pathname === loginPath) {
    return children;
  }
  const { loading } = use(AuthContext);

  if (loading) {
    return <Loading />;
  }

  return children;
};
